import { captureEvent } from '@/lib/util/captureEvent';
import { useRef, useEffect } from 'react';
import { IntrinsicProps, createComponent } from 'react-commons';

export enum YollaType {
  DESKTOP_MEDRECT = 'desktop-medrec-template',
  DESKTOP_LEADERBOARD = 'desktop-leaderboard-template',
  MOBILE_LEADERBOARD = 'mobile-leaderboard-template',
}

export enum YollaSubType {
  DESKTOP_MEDRECT = '320x250',
  DESKTOP_LARGERECT = '336x280',
  DESKTOP_HALFPAGE = '300x600',
  DESKTOP_LEADERBOARD = '728x90',
  MOBILE_LEADERBOARD = '320x50',
  MOBILE_ADHESION = '320x50',
}

export interface YollaProps extends IntrinsicProps {
  type: string
  subType: string
}

export default createComponent<YollaProps>('AdYolla', {}, function AdYolla ({}, props) {
  const subTypes = Array.isArray(props.subType)
    ? props.subType.join(',')
    : props.subType;

  const firstRenderRef = useRef(true);
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      if (window.location.href.includes('utm')) {
        captureEvent('ad_impression', { 
          tag_name: 'ad_impression_display',
          ad_unit: props.subType,
        });
      }
    }
  });

  return (
    <div className='ympb_target' data-type={props.type} data-subtype={subTypes}></div>
  );
});
